import React from "react";
import clsxm from "../../utility/clsxm";

const PrimaryButton = ({ onClick, text, className, disable }) => {
  return (
    <button
      onClick={() => {
        if (onClick) onClick();
      }}
      disabled={disable}
      className={clsxm(
        "border-none w-fit object-contain bg-primaryPink text-white text-lg text-center rounded-[10px] cursor-pointer h-[46px] px-5 hover:scale-105 transition-transform",
        disable ? "bg-[#AEAEAE] cursor-not-allowed" : "",
        className
      )}
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
