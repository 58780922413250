import React from "react";
import ProtectedRoutesLayout from "../../../layout/ProtectedRoutesLayout/ProtectedRoutesLayout";
import PrimaryHeading from "../../../components/primitives/PrimaryHeading";
import { useSelector } from "react-redux";

const AuthLanding = () => {
  const user = useSelector((state) => state.auth.user);
  return (
    <ProtectedRoutesLayout>
      <div className="flex flex-col gap-6 text-center">
        <PrimaryHeading heading={`Hi ${user?.name}`} />
        <PrimaryHeading heading="WELCOME TO YOUR GROWTH SPACE" />
      </div>
    </ProtectedRoutesLayout>
  );
};

export default AuthLanding;
