import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const useMutationInstance = ({ onSuccess, onError }) => {
  const executeMutation = async ({ url, data }) => {
    try {
      const response = await axios.post(url, data);
      if (onSuccess) {
        onSuccess(response);
      }
      return response;
    } catch (err) {
      if (onError) {
        onError(err);
      }
      throw err;
    }
  };

  // React Query's useMutation
  const mutation = useMutation({
    mutationFn: executeMutation,
  });

  return mutation;
};

export default useMutationInstance;
