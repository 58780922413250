import React, { useEffect, useRef } from "react";

import "./TestimonialCarousel.css";
import TestimonialCard from "../TestimonialCard/TestimonialCard";

import TruongAvatar from "../../images/landing/testimonials/kieu.webp";
import LindaAvatar from "../../images/landing/testimonials/Linda.webp";
import HannahAvatar from "../../images/landing/testimonials/Hannah.webp";
import MayaAvatar from "../../images/landing/testimonials/Maya.webp";
import ArchanaAvatar from "../../images/landing/testimonials/Archana.webp";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper/modules";

const TestimonialCarousel = () => {
  const profiles = [
    {
      name: "Truong Xuan Kieu",
      text: "My favorite part is scenarios which are diverse and quite close to our real-life situations. It gave me more confidence and skills to solve my issues.",
      designation: "Business Analyst, Vietnam",
      avatar: TruongAvatar,
    },
    {
      name: "Linda Christine",
      text: "I realized it's important to have these difficult and comfortable conversations and their importance in the work environment.",
      designation: "Researcher, Uganda",
      avatar: LindaAvatar,
    },
    {
      name: "Hannah Ajayi",
      text: "My interaction with the AI character was extremely engaging and realistic. It felt like I was in a real-world setting.",
      designation: "Senior Programmer, Nigeria",
      avatar: HannahAvatar,
    },
    {
      name: "Maya Sterling",
      text: "It was really fascinating to see that one can actually learn how to negotiate effectively with an AI character.",
      designation: "Senior Data Analyst, Canada",
      avatar: MayaAvatar,
    },
    {
      name: "Archana Boppolige",
      text: "The virtual environment helped me mirror an emotionally charged real-life situation. And the virtual environment with the avatars made really felt liek a safe space.",
      designation: "Academic writer, India",
      avatar: ArchanaAvatar,
    },
  ];

  return (
    <>
      <Swiper
        breakpoints={{
          0: {
            // for screens with a width from 0px and up
            slidesPerView: 1,
          },
          768: {
            // for screens with a width from 768px and up
            slidesPerView: 1,
          },
          1024: {
            // for screens with a width from 1024px and up
            slidesPerView: 1.9,
          },
        }}
        centeredSlides={true}
        loop={true}
        spaceBetween={10}
        pagination={false}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {profiles.map((profile, idx) => {
          return (
            <SwiperSlide key={idx}>
              <TestimonialCard profile={profile}></TestimonialCard>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default TestimonialCarousel;
