import React, { useEffect, useRef } from "react";
import "./Taara.css";
import TaaraTechy from "../../images/taara/TaaraTechy.webp";

import AskTaaraForm from "../../components/AskTaaraForm/AskTaaraForm";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import LazyImageObjectFit from "../../components/LazyImage/LazyImageObjectFit";
import LazyVideo from "../../components/LazyVideo/LazyVideo";
import useScreenDetector from "../../hooks/useScreenDetector";
import Header from "../../components/Header/Header";
import { instagramCards } from "../../data/instagramPosts";

const Taara = () => {
  const { isMobile } = useScreenDetector();
  const sectionRefs = {
    TaaraFirstIntro: useRef(null),
    TaaraFaceArea: useRef(null),
    TaaraOneLiners: useRef(null),
    TaaraReason: useRef(null),
    TaaraSocial: useRef(null),
    TaaraThoughts: useRef(null),
    TaaraDescription: useRef(null),
    TaaraStoryShare: useRef(null),
    TaaraMissionArea: useRef(null),
    WhyTaaraCreatedArea: useRef(null),
    AskTaaraArea: useRef(null),
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Trigger when 50% of the element is visible
    };

    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in");
          entry.target.classList.remove("fade-out"); // Ensure fade-out is removed
        } else {
          entry.target.classList.remove("fade-in");
          entry.target.classList.add("fade-out"); // Optionally, add fade-out for exit animation
        }
      });
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    // Observe all sections
    Object.values(sectionRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      // Cleanup observer on unmount
      observer.disconnect();
    };
  }, []);
  return (
    <NonProtectedLayout>
      <Header
        title="Empower Your Future: Leadership Training for Women | Taara Quest"
        description="Join Taara Quest’s women leadership program to enhance your skills. Our
executive coaching helps women excel in leadership roles. Explore our
training programs today!"
        keywords="leadership training for women, women in leadership, women leadership
program, female leadership training, women in leadership program,
executive coaching for women"
      />
      <div className="TaaraContainer">
        <div className="TaaraIntroImage">
          <div className="TaaraIntroLeftImage"></div>
          <div className="TaaraIntroRightImage">I'M NOT A HERO</div>
        </div>
        <div className="TaaraFirstIntro" ref={sectionRefs.TaaraFirstIntro}>
          <div className="TaaraFirstIntroTopText">
            That's the first thing that you <br />
            should know about me, I didn't
            <br /> get into this thinking I would
            <br />
            become a hero,
          </div>
          <div className="TaaraFirstIntroBottomText">
            Let alone have a video game
            <br />
            named after me?
          </div>
        </div>
        <div className="TaaraFaceArea" ref={sectionRefs.TaaraFaceArea}>
          I was a woman who got a job
          <br />
          <span>and then I started noticing</span>
        </div>
        <div className="TaaraOneLiners" ref={sectionRefs.TaaraOneLiners}>
          <span>The interruptions.</span>
          <span>The condescension.</span>
          <span>The blatant discrimination.</span>
        </div>
        <div className="TaaraReason" ref={sectionRefs.TaaraReason}>
          And like so many other women out
          <br />
          there, I decided I'd had enough of it.
          <span>And that's how Taara Quest was born.</span>
        </div>

        <div className="TaaraSocial" ref={sectionRefs.TaaraSocial}>
          <div className="TaaraSocialHeading">TAARA ON INSTAGRAM</div>
          <div className="TaaraSocialCards">
            {instagramCards.map((instaCard, idx) => (
              <div className="InstagramCard flex-shrink-0" key={idx}>
                {isMobile ? (
                  <LazyImageObjectFit
                    src={instaCard.image}
                    alt="instagram"
                    className="w-[240px] h-[360px] object-cover"
                  />
                ) : (
                  <LazyImageObjectFit
                    src={instaCard.image}
                    alt="instagram"
                    className="w-[400px] h-[550px] object-cover"
                  />
                )}
              </div>
            ))}
          </div>
          <a
            href="https://www.instagram.com/taara.aibigsister/"
            className="TaaraSocialButton"
          >
            Follow Taara
          </a>
        </div>
        <div className="TaaraThoughts" ref={sectionRefs.TaaraThoughts}>
          Now I Know What You're Thinking
          <span>
            But Taara, if you're a kick-ass-system-
            <br />
            destroying-sexism-defeating-warrior-woman
            <br />
            how come you're just an influencer now??
          </span>
        </div>
        <div className="TaaraDescription" ref={sectionRefs.TaaraDescription}>
          <div className="TaaraLeftDescription">
            <LazyImageObjectFit
              src={TaaraTechy}
              alt="Taara Techy"
              className="w-full h-[415px] object-cover rounded-[20px] mobile:h-[323px]"
            />
            <div className="w-full hidden items-center justify-center opcity-0 absolute bottom-6 mobile:flex">
              <p className="text-white text-[12px] w-[60%] text-center">
                First off - I'm not *just* anything. No one is. But, I'm not
                only here to guide you through your quest.
              </p>
            </div>
          </div>
          <div className="TaaraRightDescription">
            {isMobile ? (
              <span className="text-center">
                I'm here to share the stories of every woman who has experienced
                the BS of the corporate world. Harnessing power of media lets me
                do that.
              </span>
            ) : (
              <>
                <span>
                  First off - I'm not<b>*just*</b> anything. No one is.
                </span>
                <span>
                  But, I'm not only here to guide you through
                  <br />
                  your quest...
                </span>
                <span>
                  I'm here to share the stories of every
                  <br /> woman who has experienced the BS of the
                  <br />
                  corporate world.
                </span>
                <span>Harnessing power of media lets me do that.</span>
              </>
            )}
          </div>
        </div>
        <div className="TaaraStoryShare" ref={sectionRefs.TaaraStoryShare}>
          So, Let's Share Our Stories...<span>And Then We'll Rewrite Them</span>
        </div>
        <div className="TaaraIntroductionVideoTitle">
          Now, For a Proper Introduction - Watch This!
        </div>
        {/* <video
          className="TaaraIntroVideoArea"
          src="https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Taara+Demo+Video+1.mp4"
          controls
          poster="https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/Taara+Introduction+Video+Thumbnail_Taara_Page_first+video.png"
          preload="none"
        ></video> */}
        <LazyVideo
          className="TaaraIntroVideoArea"
          src="https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Taara+Demo+Video+1.mp4"
          poster="https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/Taara+Introduction+Video+Thumbnail_Taara_Page_first+video.png"
        />
        <div className="TaaraMissionArea" ref={sectionRefs.TaaraMissionArea}>
          <div className="TaaraMissionLeft">
            THE MISSION<span>behind Taara</span>
          </div>
          <div className="TaaraMissionRight">
            {isMobile ? (
              <>
                <p className="para">
                  {" "}
                  Creating Taara isn’t just about putting out another AI
                  Influencer for us at Taara Quest. It’s about using technology
                  to amplify the challenges and voices of women!
                </p>
              </>
            ) : (
              <>
                <p className="para">
                  Creating Taara isn’t just about putting out another AI
                  Influencer for us at Taara Quest.
                </p>
                <p className="para">
                  It’s about using technology to amplify the challenges and
                  voices of women!
                </p>
              </>
            )}
            <p className="para font-bold text-primaryBlue">
              Taara isn’t just an experiment; she’s a revolution. She’s the
              stubborn symbol of purpose and calling we all carry within us.
            </p>
          </div>
        </div>
        <div
          className="WhyTaaraCreatedArea"
          ref={sectionRefs.WhyTaaraCreatedArea}
        >
          <div className="WhyTaaraCreatedTitle">
            Want to know more about how
            <br />
            and why I was created?
          </div>
          <LazyVideo
            className="w-[1003px] h-[720px] rounded-[20px] mobile:h-auto"
            src="https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Taara-Behind-the-Scenes-Updated-Video.mp4"
            poster="https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/The+Making+Of+Video+Thumbnail_Taara_Page_second+video.png"
          />
        </div>
        <AskTaaraForm ref={sectionRefs.AskTaaraArea} />
      </div>
    </NonProtectedLayout>
  );
};

export default Taara;
